.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 清除内外边距 */
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, /* structural elements 结构元素 */
dl, dt, dd, ul, ol, li, /* list elements 列表元素 */
pre, /* text formatting elements 文本格式元素 */
fieldset, lengend, button, input, textarea, /* form elements 表单元素 */
th, td { /* table elements 表格元素 */
    margin: 0;
    padding: 0;
}

h1 { font-size: 18px; /* 18px / 12px = 1.5 */ }
h2 { font-size: 16px; }
h3 { font-size: 14px; }
h4, h5, h6 { font-size: 100%; }

address, cite, dfn, em, var { font-style: normal; } /* 将斜体扶正 */
code, kbd, pre, samp, tt { font-family: "Source Han Sans CN", Courier, monospace; } /* 统一等宽字体 */
small { font-size: 12px; } /* 小于 12px 的中文很难阅读，让 small 正常化 */

/* 重置列表元素 */
ul, ol { list-style: none; }

/* 重置文本格式元素 */
a { text-decoration: none; }

abbr[title], acronym[title] { /* 注：1.ie6 不支持 abbr; 2.这里用了属性选择符，ie6 下无效果 */
border-bottom: 1px dotted;
cursor: help;
}

q:before, q:after { content: ''; }

/* 重置表单元素 */
legend { color: #000; } /* for ie6 */
fieldset, img { border: none; } /* img 搭车：让链接里的 img 无边框 */
/* 注：optgroup 无法扶正 */
button, input, select, textarea {
    font-size: 100%; /* 使得表单元素在 ie 下能继承字体大小 */
}

/* 重置表格元素 */
table {
border-collapse: collapse;
border-spacing: 0;
}

/* 重置 hr */
hr {
    border: none;
    height: 1px;
}

input:focus,button:focus{
  outline:none;
}
p,li{
  font-family:Source Han Sans CN;
}
p,span{
  cursor: default;
}
button{
  cursor: pointer;
}
.red{
  color: #E92D08;
}
.yellow{
  color: #FF8B33;
}
.green{
  color: #28B068;
}
.ant-table-cell{
  text-align: center !important;
}
tr .ant-table-cell:first-of-type{
  text-align: left !important;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder td{
  text-align: center !important;
  line-height: 300px !important;
  font-size: 15px;
  border: none;
}
.empty{
  text-align: center;
  line-height: 300px;
  font-size: 15px;
  border: none;
  color: rgba(0, 0, 0, 0.25);
}
.loading{
  position: absolute;
  left: 50%;top: 50%;
  transform: translate(-50%,-50%);
}
.ant-modal .ant-modal-footer{
  display: flex;
  justify-content: center;
}
.ant-modal .ant-modal-footer button{
  width: 90px;
}
.ant-modal .ant-modal-footer button:nth-of-type(2){
  margin-left: 20px;
}