.ContentMain .ant-menu-dark .ant-menu-inline.ant-menu-sub{
    background-color: #353B4D;
}
.ContentMain .ant-menu-submenu-selected div,.ant-menu-submenu-selected div:hover{
    background-color: #28BBAC !important;
}
.ContentMain .ant-menu-submenu-selected .ant-menu-item-selected{
    background: none !important;
    color: #28BBAC;
}
.ContentMain .ant-menu-submenu-selected .ant-menu-item-selected:hover{
    color: #28BBAC;
}
.ContentMain .teacherMenu{
    margin-top: 30px;
}
.ContentMain .teacherMenu>li span{
    font-size: 16px;
}
.ContentMain .portrait {
    color: #F5F5F5;
    text-align: center;
}
.ContentMain .portrait .headImg{
    width: 70px;
    height: 70px;
    margin: 40px auto 0;
    border-radius: 50%;
    border:2px solid #fff;
    overflow: hidden;
    position: relative;
    background-color: white;
}
.ContentMain .portrait .headImg img{
    width: 100%;
    height: 100%;
}
.ContentMain .portrait p:nth-of-type(1){
    margin-top: 15px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
}
.ContentMain .portrait p:nth-of-type(2){
    margin-top: 5px;
    line-height: 12px;
    font-size: 12px;
}
.ContentMain .ant-layout-header{
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 10px 0px rgba(9,9,9,0.09);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ContentMain .ant-layout-content{
    position: relative;
    z-index: 1;
    overflow: auto;
    height: calc(100vh - 188px);
}
.ContentMain .ant-layout-footer{
    position:fixed;
    left: 50%;
    transform: translateX(-30%);
    bottom: 0;
    z-index: 0;
}
.ContentMain .logo,.userControl{
    display: flex;
    margin-left: 36px;
}
.ContentMain .logo img{
    width: 162px;
    height: 34px;
    padding: 0 10px;
    box-sizing: content-box;
}
.ContentMain .userControl p{
    margin-right: 45px;
    color: #333333;
}
.ContentMain .userControl p:hover{
    color: #28BBAC;
}
.ContentMain .logo img:first-of-type{
    border-right: 2px solid #556E81;
}
.iconfont{
    margin-right: 10px;
}
.ant-menu-submenu-title{
    font-size: 16px;
}

/* .site-layout-background{
    background-color: #f0f2f2 !important;
} */